import styles from "./ThirdScreen.module.css"



const ThirdScreen=()=>{

 


return <>
 <h1 className={styles.title}>may line</h1>
{/* <div className={styles.explain}>קורס ללימוד ציפורניים שיהפוך אותך למניקוריסטית בסטנדרט הגבוה ביותר <strong>ויוציא אותך עם:</strong></div> */}

</>


}
export default ThirdScreen